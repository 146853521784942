import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Upload from './Upload';
import Anim from './Anim';

class App extends Component {
    
    render() {
        
        return (
            <div>
                <Router>
                    <Route exact path="/" component={Upload}/>
                    <Route path="/animation/:id" component={Anim}/>
                </Router>
            </div>
        );
    }
}

export default App;
