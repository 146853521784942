import React, { Component, Fragment } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';



import axios from 'axios';
import { FaPlay, FaPause, FaRedo, FaForward, FaBackward, FaFacebookF, FaTwitter, FaClipboard} from "react-icons/fa";


import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, {NavigationControl, ScaleControl, Popup as Pop} from 'react-map-gl';
import copy from 'copy-to-clipboard';
import Share from 'easy-share-popup';

import MAP_STYLE from './map-style-basic-v8.json';
import { fromJS } from 'immutable';
import { API_URL } from './constants.js';

import { timeDay } from 'd3-time'

import {
    Sparklines,
    SparklinesLine, 
    SparklinesReferenceLine,
} from 'react-sparklines';


const getLayerIndex = () => MAP_STYLE.layers.findIndex(layer => layer.id === 'data')



const DateRangeWrapper = styled.div`
    position: absolute;
    top: 150px;
    left: 10px;
    z-index: 1;
    opacity: ${props => props.invisible ? '0.2' : '1'};
    opacity: ${props => props.show ? props.invisible : '0'};
    &:hover {
        opacity: 1;
    }
`

const Player = styled.div`
    position: absolute;
    bottom: 0px;
    left: 8%;
    z-index: 1;
    opacity: ${props => props.invisible ? '1' : '0.2'};
    &:hover {
        opacity: 1;
    }
    background-color: white;
`

const PlayerButton = styled.button`
    background-color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    title: ${props =>props.title};
`

const Chart = styled.div`
    position: absolute;
    right: 1%;
    bottom: 50px;
    transform: translateX(-35%);
    padding: 10px;
    z-index: 20;
    font-size: 22px;
    font-weight: 300;
    background-color: rgba(255, 255, 255, 0.5);
    width: 250px;
`


const ChartText = styled.text`
    fill: darkred;
    font-weight: bold;
    font-style: normal;
`
const MaxLineText = (props) => {
    const { points, text, margin } = props
    const ypoints = points.map(p => p.y);
    const y = Math.min.apply(Math, ypoints);
    return (
        <ChartText x={2} y={y + margin + 13} fontSize={13}>
            { text }
        </ChartText>
    )
}

const TitleWrapper = styled.div`
    position: absolute;
    top: 60px;
    left: 10px;
    z-index: 1;
`

const Title = styled.h3`
    font-size: 40px;
    margin: 5px 0;
`

const Desc = styled.p`
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 5px;
    background-color: white;
    padding: 5px;
`

const dataLayer = fromJS({
    id: 'data',
    source: 'coords',
    type: 'circle',
    interactive: true,
    paint: {
        "circle-color": ["get", "color"],
        "circle-radius": ["get", "size"],
        "circle-opacity": 0.5,
    },
})


const formatDate = (date) => {
    // Taken from: https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date 
    var monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
      ];
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
}



class Anim extends Component {

    state = {
        dateRange: {
            selection: {
                startDate: null,
                endDate: null,
                key: 'selection'
            }
        },
        coords: {},
        viewport: {
            width: "100%",
            height: "100vh",
            latitude: 28.4,
            longitude: 2.6,
            zoom: 1.5
        },
        mapStyle: fromJS(MAP_STYLE),
        currentPoint: null,
        currentDate: null,
        timeRange: [],
        isAnimationPlaying: false,
        animationStep: 0,
        n_cases: [],
        total_cases: [0],
        max_cases: 0
    }
    step = 600
    timer = null
    
    reverseTime(time) {
        var time_temp = time.split("/")
        var day_temp = time_temp[0]
        time_temp[0] = time_temp[1]
        time_temp[1] = day_temp
        return time_temp.join("/")

    }
    componentDidMount() {
        if(this.props.match.params.id === "EXAMPLE") {
            this.setState({
                viewport: { 
                    width: "100%",
                    height: "100vh",
                    latitude: 37.759554, 
                    longitude: -122.452451,
                    zoom: 12
                }
            })
        }
        axios.get(`${API_URL}/data/${this.props.match.params.id}`)
             .then(response => response.data)
             .then(data => {
                 
                 const mapStyle = fromJS(MAP_STYLE)
                     .setIn(['sources', 'coords'], fromJS({type: 'geojson', data: data.coords}))
                     .set('layers', fromJS(MAP_STYLE).get('layers').push(dataLayer));
                 const time_end = new Date(this.reverseTime(data.coords.features[data.coords.features.length -1].properties.Date))                 
                 const time_init = new Date(this.reverseTime(data.coords.features[0].properties.Date))
                 this.setState({
                     dateRange: {selection: {
                         startDate: time_init,
                         endDate: time_end,
                         key: 'selection'
                     }},
                     coords: data.coords,
                     title: data.title,
                     desc: data.desc,
                     mapStyle: mapStyle
                 })
             })
    }

    handleHover = (event) => {
        if (event) {
            if (event.features) {
                if (event.features.length > 0) {
                    var features = event.features.find(f => f.source === 'coords')
                    if (features) {
                        this.setState({currentPoint: features})
                    } else {
                        this.setState({currentPoint: null})
                    }
                }
            }
        }
    }


    filterByDate = (from, to) => {
        const delta = to - from
        if (delta > 86400000*14) {
            // min delta is 14 days
            from = new Date(to - 86400000*14)
        }
        const filter = {filter: [
            "all",
              [">=", "timestamp", from.getTime()],
              ["<=", "timestamp", to.getTime()],
        ]}
        return dataLayer.merge(filter)
    }

    pauseAnimation = () => {
        this.setState({ isAnimationPlaying: false })
        if (this.timer) {
            clearInterval(this.timer)
        }
    }
    fastBackward = () => {
        this.pauseAnimation()
        this.step = this.step*2
        
    }
    fastFordward = () => {
        this.pauseAnimation()
        this.step = this.step/2
        
    }

    ShareFa = () => {
        var share = new Share(`${API_URL}/animation/${this.props.match.params.id}`)
        share.facebook()
    }

    ShareTi = () => {
        var share = new Share(`${API_URL}/animation/${this.props.match.params.id}`)
        share.twitter()
    }

    Copy = () => {
        copy(`${API_URL}/animation/${this.props.match.params.id}`)

    }

    Redo =() => {
        this.setState({
            animationStep: 0,
            currentDate: null,
            n_cases: [],
            total_cases: [0],
            max_cases: 0
        })
    }
    playAnimation = () => {
        const { dateRange } = this.state
        const features = this.state.coords.features
        const min = new Date(dateRange.selection.startDate)
        var max = new Date(dateRange.selection.endDate)
        max.setDate(max.getDate() + 1)
        const timeRange = timeDay.range(min, max)
        if (!this.state.isAnimationPlaying && 
            this.state.animationStep >= timeRange.length) {
            this.Redo()

        }
        this.timer = setInterval(() => {
            if (this.state.animationStep >= timeRange.length) {
                this.pauseAnimation()
                return
            }
            const day = timeRange[this.state.animationStep]
            var layer = this.filterByDate(min, day)
            
            const cases = features.filter(f =>
                (f.properties.timestamp < day.getTime()) &&
                (f.properties.timestamp >= (day.getTime() - 86400000))
            )
            var n = 0
            for (var i = 0; i<cases.length; i++) {
                n = n + cases[i].properties.Cases

            }
            const opacity = [
                "let", "timedelta",
                ["abs",
                    ["-", day.getTime(), 
                        ["number", ["get", "timestamp"]]
                    ],
                ],

                ["case", ["<=" , ["var", "timedelta"], 86400000],
                    0.7, 0.4 
                ]
            ]
            const color = [
                "let", "timedelta",
                ["abs",
                    ["-", day.getTime(),
                        ["number", ["get", "timestamp"]]
                    ],
                ],
                        [ 'get', 'color']
            ]

            layer = layer.setIn(['paint', 'circle-opacity'], opacity)
            layer = layer.setIn(['paint', 'circle-color'], color)
            this.setState((state) => {
                const { mapStyle, n_cases, total_cases, max_cases } = state
                return {
                    mapStyle: mapStyle.setIn(['layers', getLayerIndex()], layer), 
                    isAnimationPlaying: true,
                    animationStep: state.animationStep + 1,
                    currentDate: formatDate(day),
                    timeRange: timeRange,
                    n_cases: [...n_cases, n],
                    total_cases: [...total_cases, total_cases[total_cases.length - 1] + n],
                    max_cases: n > max_cases ? n : max_cases
                }
            })
        }, this.step)
    }

    render() {
        const { currentDate, currentPoint, n_cases, total_cases, max_cases } = this.state
        return (
            <Fragment>
                
                { currentDate &&
                <Draggable>
                    <Chart>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 22,
                            fontStyle: "normal",
                            fontWeight: "bold",
                            marginBottom: 6
                        }}>
                            <span>{ currentDate }</span>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 18,
                            fontStyle: "normal",
                            marginBottom: 6
                        }}>
                            <span> Cases per Day: { n_cases[n_cases.length - 1] } </span>
                            
                        </div>
                        <div>
                            <Sparklines data={n_cases}
                                        limit={n_cases.length}
                                        margin={0}>
                                <SparklinesLine color="red"/>
                                <SparklinesReferenceLine type="max"/>
                                <MaxLineText text={`Peak: ${max_cases}`}/>
                                
                            </Sparklines>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 18    ,
                            fontStyle: "normal",
                            marginBottom: 6,
                            marginTop: 10
                        }}> 
                            <span> 
                                Cumulative cases: { total_cases[total_cases.length - 1] }
                            </span>
                        </div>    
                        <div>
                            <Sparklines data={total_cases}
                                        limit={total_cases.length}
                                        margin={0}>
                                <SparklinesLine color="red"/>
                                <SparklinesReferenceLine type="max"/>                                
                            </Sparklines>
                        </div>
                        
                    </Chart>
                </Draggable>
                }
                { Object.keys(this.state.coords).length > 0 &&
                       
                        <Player invisible={this.state.isAnimationPlaying ? false : true}>
                             
                        
                        <PlayerButton onClick={this.fastBackward}>
                                   <FaBackward/>
                               </PlayerButton>
                               <font size='3'> {600/this.step}x </font>
                            <PlayerButton onClick={this.fastFordward}>
                                   <FaForward/>
                               </PlayerButton>
                       
                               { !this.state.isAnimationPlaying ?
                               (<PlayerButton onClick={this.playAnimation}>
                                   <FaPlay/>
                               </PlayerButton>) :
                               (<PlayerButton onClick={this.pauseAnimation}>
                                   <FaPause/>
                               </PlayerButton>)
                            }
                            <PlayerButton onClick={this.Redo}>
                                <FaRedo/>
                            </PlayerButton>
                            <PlayerButton onClick={this.ShareFa}>
                                <FaFacebookF />
                            </PlayerButton>
                            <PlayerButton onClick={this.ShareTi}>
                                <FaTwitter />
                            </PlayerButton>
                            <PlayerButton onClick={this.Copy}>
                                <FaClipboard />
                            </PlayerButton>
                            
                        </Player>     
                }
                <TitleWrapper>
                   { this.state.title !== '' &&
                        <Title> {this.state.title} </Title>
                    }
                    { this.state.desc !== '' &&
                        <Desc> {this.state.desc} </Desc>
                    }
                </TitleWrapper>
                <DateRangeWrapper invisible={this.state.coords['features'] === undefined ? false : true}
                    show={this.state.isAnimationPlaying ? false : true}>
                
                    <DateRange ranges={[this.state.dateRange.selection]}
                               onChange={(dateRange) => {
                                 const layer = this.filterByDate(dateRange.selection.startDate, dateRange.selection.endDate)
                                 this.setState((state) => ({
                                     dateRange,
                                     mapStyle: state.mapStyle.setIn(['layers', getLayerIndex()], layer)
                                 }))}
                             }>
                    </DateRange>
                    
                </DateRangeWrapper>
                <ReactMapGL {...this.state.viewport}
                            onViewportChange={(viewport) => this.setState({viewport})}
                            mapStyle={this.state.mapStyle}
                            onHover={this.handleHover}
                            mapboxApiAccessToken={window._env_.REACT_APP_TOKENID}>
                            
                                
                                <div style={{position: 'absolute',top: 60,right: 0,padding: '10px'}}>
                                 <NavigationControl onViewportChange={(viewport) => this.setState({viewport})} showCompass={false}/>
                                </div>
                                <div style={{position: 'absolute',bottom: 15,right: 0,padding: '10px'}}>
                                    <ScaleControl maxWidth={200} unit="metric"/>
                                </div> 
                            

                     { currentPoint &&
                         <Pop latitude={currentPoint.geometry.coordinates[1]}
                                longitude={currentPoint.geometry.coordinates[0]}
                                anchor="top"
                                closeButton={false}
                                closeOnClick={false}
                                onClose={() => this.setState({currentPoint: null})}>

                                <div> 
                                    <div> Label: {currentPoint.properties.Label} </div>
                                    <div> Cases: {currentPoint.properties.Cases} </div>
                                    <div> Date: {`${currentPoint.properties.day}/${currentPoint.properties.month}/${currentPoint.properties.year}`} </div>
                                </div>
                         </Pop>
                     }
                </ReactMapGL>
               
            </Fragment>
            
        );
    }
}

export default Anim;
