import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Spinner from 'react-spinkit';

import Dropzone from 'react-dropzone';
import { API_URL } from './constants.js';


const Centralized = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Upload = styled.section`
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
`

const Label = styled.h3`
    display: flex;
    font-size: 1.5em;
    font-weight: 300;
    color: white;
    justify-content: space-between;
`

const InputWrapper = styled.div`
    padding: 20px;
`

const Title = styled.input`
    padding: 10px;
    margin-bottom: 10px;
    font-family: Roboto, sans-serif;
`

const Description = styled.textarea`
    padding: 10px;
    margin-bottom: 10px;
    resize: none;
    font-family: Roboto, sans-serif;
`

const Flex = styled.div`
    display: flex;
    flex-direction: column;
`

class UploadPage extends Component {

    state = {
        uploading: false,
        currentFile: null,
        title: '',
        desc: ''
    }

    onDrop = (file) => {
        const formData = new FormData()
        formData.append('coords', file[0])
        formData.append('title', this.state.title)
        formData.append('desc', this.state.desc)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        this.setState({ uploading: true })
        axios.post(`${API_URL}/upload`, formData, config)
            .then(response => response.data)
            .then(data => {
                this.props.history.push(`/animation/${data.id}`)
            })

    }

    render() {
        return (
            <div>
           
                <Centralized>
                    
                    <div class="col-md-13 col-md-offset-0 inner col-xs-10 col-xs-offset-1 col-sm-6" style={{marginTop:"100px"}}>
                        <div class="text-box">
                            <p class="intro"></p>
                            <h2>OUTBREAK</h2>
                            <h3>surveillance</h3>
                        </div>
  			        </div>
                    <Flex>
                        <Title placeholder='Title' value={this.state.title} onChange={e => this.setState({title: e.target.value})}/>
                        <Description placeholder='Description' value={this.state.desc} onChange={e => this.setState({desc: e.target.value})}/>
                        <Dropzone onDrop={this.onDrop}>
                            {({getRootProps, getInputProps}) => (
                                <Upload>
                                    <InputWrapper {...getRootProps()}>
                                        <input {...getInputProps()}/>
                                        <Label> 
                                            { this.state.uploading ?
                                             (<>
                                               <span> Uploading... </span>
                                               <Spinner 
                                                   name='double-bounce'
                                                   fadeIn='none'
                                                   color='red'
                                                   style={{ marginLeft: 10 }}/>
                                              </>)
                                                     : ( this.state.currentFile ? 
                                                        <span> { this.state.currentFile } </span> :
                                                        <span> Upload your file here </span>
                                                      )
                                            }
                                        </Label>
                                    </InputWrapper>
                                </Upload>
                            )}
                        </Dropzone>
                    </Flex>
                    <br/><br/><br/>
                    <div class="desc">
                        <p><span class="specialchar">OUTBREAK</span> facilitates surveillance by showing in an animated graph the timeline and geolocations of cases of an outbreak</p><br/>
                        <p>See more <a href="/static/about.html">about</a> OUTBREAK and follow our <a href="/static/tutorial.html">tutorial</a> to learn how to use it. 
                        Check our paper <a href="https://arxiv.org/abs/2004.10490" target="blank">here</a>.</p><br/>
                        <img src="/static/images/COVID19.gif" alt="COVID19" class="img-responsive center-block" style={{width:"60%", padding:"20px"}}/><br/>

                    </div>
                    <div class="container-fluid" style={{margin:"20px"}}>
                        <div class="col-md-12 col-sm-12 col-xs-12 vcenter" style={{textAlign:"center"}}>
                                <p> Created and maintained by: </p>
                                <a href="http://csbiology.com" target="blank">
                                    <img height="100" src="/static/images/logo-arrows-low.png" alt="CSBL"/></a>
                                <a href="http://integrativebioinformatics.me/" target="blank">
                                    <img height="100" src="/static/images/lib-logo-low.png" alt="LIB"/></a>
                        </div>
                    </div>

                    <footer>
                        <div class='container'>
                            <div style={{textAlign:"center", fontSize:"14px"}}>
                                <hr/>
                                <p> Copyright &copy; <span id='year'>2020</span></p>
                                <p><a href="http://csbiology.com/" target="blank">Computational Systems Biology Laboratory</a> - <a href="https://www5.usp.br/" target="blank">University of São Paulo</a></p>
                                <p><a href="https://integrativebioinformatics.me/" target="blank">Laboratory of Integrative Bioinformatics</a> - <a href="https://www.uchile.cl/" target="blank">University of Chile</a></p>
                            </div>
                        </div>
                    </footer>
                </Centralized>
            </div>
        );
    }
}

export default UploadPage;
